<nav class="navbar navbar-expand-lg navbar-dark bg-dark mb-3">
  <img src="./assets/img/academias.png" width="36" class="mr-3">
  <a class="navbar-brand" href="#">Academias EMSAD</a>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto" *ngIf="this.aService.logueado">

      <li *ngFor="let m of this.aService.menu" routerLinkActive='active' class="_cursor">
        <a *ngIf="m.url!=0" class="nav-link" routerLink="{{m.url}}">{{m.titulo}}</a>
        <div *ngIf="m.url==0" ngbDropdown class="d-inline-block">
          <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle>{{m.titulo}}</a>
          <div ngbDropdownMenu>
            <a *ngFor="let sm of m.submenu" ngbDropdownItem routerLink='{{sm.url}}'>{{sm.titulo}}</a>
          </div>
        </div>
      </li>

    </ul>


    <form *ngIf="this.aService.logueado" class="form-inline my-2 my-lg-0">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" routerLinkActive="active">
          <div ngbDropdown class="d-inline-block" display="dynamic">
            <a class="nav-link _cursor" id="dropdownBasic1" ngbDropdownToggle>Administración</a>
            <div ngbDropdownMenu>
              <!-- <a ngbDropdownItem routerLink='micuenta'>Mi Cuenta</a> -->
              <a ngbDropdownItem></a>
              <a ngbDropdownItem routerLink='' (click)="salir();">Salir</a>
            </div>
          </div>
        </li>
      </ul>
    </form>
  </div>
</nav>

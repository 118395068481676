import { environment } from './../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { ControlService } from './control.service';

import {
  Evento,
  Planteles,
  Respuesta,
} from './../interfaces/materias.interface';
import { AcademiasService } from '../servicios/academias.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  filtro: string = 'E';
  losPlantelesFil: Planteles[] = [];
  losPlanteles: Planteles[] = [];
  losPeriodos: { periodo: string }[] = [];
  losEventos: Evento[] = [];
  elPeriodo: string = '';
  elEvento: number;
  datosMuestra;
  archivoMuestra: SafeResourceUrl =
    this.sanitizer.bypassSecurityTrustResourceUrl(
      'http://localhost:3001/api/upload/academias/2021B_41_11_M_A4CS.pdf'
    );
  plantelActual: string;
  cvePlantelActual: number;
  tipoArchivoVer: string;
  totalArchivosEvento: number;
  archivosPlantelSeleccionado;

  constructor(
    private sanitizer: DomSanitizer,
    private _dService: ControlService,
    private modalService: NgbModal,
    private academiasSrv: AcademiasService
  ) {}

  guardaComentario(uniadmCve: number, uniadmDesc: string) {
    // console.log("uniadmCve => ", uniadmCve);
    Swal.fire({
      title: `Comentarios al ${uniadmDesc}`,
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      cancelButtonText: 'Cancelar',
      showCloseButton: true,
      // showLoaderOnConfirm: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (!result.isConfirmed) {
        return false;
      }
      if (result.value == '') {
        this.MensajeTemporal.fire({
          icon: 'warning',
          title: 'El comentario esta vacío',
        });
        return false;
      }
      this._dService
        .agregaComentarioPlantel(this.elEvento, uniadmCve, result.value)
        .subscribe((resp: Respuesta) => {
          this.MensajeTemporal.fire({
            icon: 'success',
            title: 'Registro Actualizado',
          });
        });
    });
  }

  muestraTodos() {
    this.filtro = 'E';
    this.losPlantelesFil = this.losPlanteles;
  }

  filtraPlanteles(tipo: string) {
    this.filtro = tipo;
    this.losPlantelesFil = this.losPlanteles.filter(
      (pl) => pl.uniadmDesc.charAt(0) == 'E'
    );
    // console.log(this.losPlantelesFil);
  }

  getPlanteles() {
    Swal.fire({
      title: 'Cargando Planteles...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading();
    this._dService
      .getPlantelesConteo(this.elEvento)
      .subscribe((resp: Respuesta) => {
        Swal.close();
        // console.log(resp);
        this.losPlanteles = resp.data;
        console.log(this.losPlanteles);
        this.losPlanteles.map((pl) => {
          // console.log(pl.uniadmAbreviatura.slice(0, 1));

          switch (pl.uniadmAbreviatura.slice(0, 1)) {
            //case 'P': pl.uniadmDesc = 'Plantel ' + pl.uniadmAbreviatura.slice(1); break;
            case 'B':
              pl.uniadmDesc = 'Bicultural ' + pl.uniadmAbreviatura.slice(1);
              break;
            case 'E':
              pl.uniadmDesc = 'Emsad ' + pl.uniadmAbreviatura.slice(1);
              break;
          }
        });
        this.losPlantelesFil = this.losPlanteles;
      });
  }

  porcentaje(cuantos: number, total: number, fix: number = 0): number {
    return parseFloat(((cuantos / total) * 100).toFixed(fix));
  }

  ngOnInit(): void {
    this.getPeriodos();
  }

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    // timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  MensajeTemporal = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  getArchivosPlantel() {
    Swal.fire({
      title: 'Actualizando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading();
    this._dService
      .getArchivosPlantelEvento(this.elEvento, this.cvePlantelActual)
      .subscribe((resp: Respuesta) => {
        Swal.close();
        this.archivosPlantelSeleccionado = resp.data;
        // console.log('--', this.archivosPlantelSeleccionado);
      });
  }

  open(content, indice: number) {
    this.datosMuestra = this.losPlantelesFil[indice];
    this.plantelActual = this.datosMuestra.uniadmDesc;
    this.cvePlantelActual = this.datosMuestra.uniadmClave;
    // console.log('datosMuestra => ', this.datosMuestra);

    this.archivosPlantelSeleccionado = '';
    this._dService
      .getArchivosPlantelEvento(this.elEvento, this.cvePlantelActual)
      .subscribe((resp: Respuesta) => {
        this.archivosPlantelSeleccionado = resp.data;
        // console.log(this.archivosPlantelSeleccionado);
      });
    this.modalService.open(content, {
      size: 'lg',
      keyboard: false,
      backdrop: 'static',
    });
  }

  cuentaArchivos(uniadmClave: number, indice: number) {
    this._dService
      .conteoArchivos(this.elEvento, uniadmClave)
      .subscribe((resp: Respuesta) => {
        let total = 0;
        let conteo = 0;
        resp.data.forEach((arc) => {
          if (arc.arcId != null) {
            conteo++;
          }
          total++;
          let archivo = {
            tpaId: arc.tpaId,
            tpaClave: arc.tpaClave,
            tpaNombre: arc.tpaNombre,
            tpaSemestre: arc.tpaSemestre,
            arcId: arc.arcId,
            arcFecha: arc.arcFecha,
            arcStatus: arc.arcStatus,
            arcConteo: arc.arcConteo,
            arcArchivo: arc.arcArchivo,
            status: arc.status,
            arcDetalles: arc.arcDetalles,
          };
          this.losPlanteles[indice].detalles.push(archivo);
        });
        let porcentaje = (conteo / total) * 100;
        this.losPlanteles[indice].total = total;
        this.losPlanteles[indice].conteo = conteo;
        this.losPlanteles[indice].porcentaje = porcentaje;
        this.losPlanteles[indice].cargado = 1;
      });
    // console.log('losPlanteles => ', this.losPlanteles);
  }

  getPeriodos() {
    this.losPeriodos = [];
    Swal.fire({
      title: 'Cargando Periodos...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading();
    this.academiasSrv.getPeriodos().subscribe((resp: Respuesta) => {
      Swal.close();
      this.losPeriodos = resp.data;
      this.elPeriodo = this.losPeriodos[0].periodo;
      this.getEventosPeriodo(this.elPeriodo);
    });
  }

  getEventosPeriodo(periodo: string) {
    Swal.fire({
      title: 'Cargando Eventos...',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading();
    this.academiasSrv
      .getEventosPeriodo(periodo)
      .subscribe((resp: Respuesta) => {
        Swal.close();
        this.losEventos = resp.data;
        this.elEvento = this.losEventos[0].eve_id;
        this._dService
          .getCuantosARchivosEvento(this.elEvento)
          .subscribe((resp: Respuesta) => {
            this.totalArchivosEvento = resp.data[0].total;
            this.getPlanteles();
          });
      });
  }

  cambia() {}

  actualizaStatusPlanteles(
    uniadmClave: number,
    arcId: number,
    status: number,
    detalle: string
  ) {
    // console.log(uniadmClave);
    // console.log(arcId);
    // console.log(status);
    let elStatus: string;
    switch (status) {
      case 1:
        elStatus = 'SUBIDO';
        break;
      case 2:
        elStatus = 'ACEPTADO';
        break;
      case 3:
        elStatus = 'REVISION';
        break;
    }
    let indice = this.losPlanteles.findIndex(
      (plantel) => plantel.uniadmClave === uniadmClave
    );
    let iDoc = this.losPlanteles[indice].detalles.findIndex(
      (doc) => doc.arcId === arcId
    );
    this.losPlanteles[indice].detalles[iDoc].arcStatus = status;
    this.losPlanteles[indice].detalles[iDoc].status = elStatus;
    this.losPlanteles[indice].detalles[iDoc].arcDetalles = detalle;
    this.datosMuestra = this.losPlanteles[indice];
  }

  revisarArchivo(archivo: string, modalArchivo, tipoDeArchivo) {
    // console.log('revisar ', archivo);
    this.tipoArchivoVer = tipoDeArchivo;
    this.archivoMuestra = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${environment.urlFile}/api/upload/academias/${archivo}`
    );
    // this.archivoMuestra = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.urlFile}:${environment.portFile}/api/upload/academias/${archivo}`);
    this.modalService.open(modalArchivo, {
      size: 'xl',
      keyboard: false,
      backdrop: 'static',
    });
  }

  rechazaArchivo(arcId: number, nombre: string) {
    Swal.fire({
      title: 'Detalles en el documento',
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Retroalimientación',
      cancelButtonText: 'Cancelar',
      showCloseButton: true,
      // showLoaderOnConfirm: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (!result.isConfirmed) {
        return false;
      }
      Swal.fire({
        title: 'Actualización de registro.',
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      Swal.showLoading();
      // console.log('Rechaza el Archivo => ', arcId);
      this._dService.rechazaArchivo(arcId, result.value).subscribe((resp) => {
        Swal.close();
        this.getArchivosPlantel();
        Swal.fire({
          title: 'Notificación por Correo Electrónico.',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        Swal.showLoading();
        this.academiasSrv
          .enviaCorreo(
            sessionStorage.getItem('correoPlantel'),
            // 'juan.herrera@cobatab.edu.mx',
            'Documento a Revisión.',
            ` <p>Director:</p>
            <p>
              El documento de <strong>${nombre}</strong> ha sido regresado para su revision.<br><br>
            </p>
            <p>
              <strong>Observaciones:</strong> <br>
              ${result.value}
            </p>
            <p>
              Gracias por el seguimiento al proceso académico.<br>
              Saludos cordiales.
            </p>

            <p align="center">
              Departamento de Programas de Estudio
              <br>
              Direccion Academica
              <br>
              <br>
              <h5>No es necesario que responda a este correo.</h5>
            </p>
            <p align="center">
              <img src="http://187.189.147.227/assets/images/logo/LOGO-COBATAB2.png" alt="logoCBT">
              <br>"Educacion que Genera Cambio"
            </p>`
          )
          .subscribe((resp: any) => {
            // console.log('ya envio Correo');
            Swal.close();

            // this.actualizaStatusPlanteles(this.cvePlantelActual, arcId, 3, result.value);
            this.MensajeTemporal.fire({
              icon: 'success',
              title: 'Archivo a revisión',
            });
          });
      });
    });
  }

  aceptaArchivo(arcId: number, nombre: string) {
    // console.log('Acepta el Archivo => ', arcId);
    Swal.fire({
      title: 'Actualización de registro.',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading();
    this._dService.aceptaArchivo(arcId).subscribe((resp) => {
      Swal.close();
      this.getArchivosPlantel();
      Swal.fire({
        title: 'Notificación por Correo Electrónico.',
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      Swal.showLoading();
      this.academiasSrv
        .enviaCorreo(
          sessionStorage.getItem('correoPlantel'),
          // 'juan.herrera@cobatab.edu.mx',
          'Documento Aceptado.',
          ` <p>Director:</p>
          <p>
            El documento de <strong>${nombre}</strong> ha sido Aceptado.<br><br>
            Gracias por el seguimiento al proceso académico.<br>
            Saludos cordiales.
          </p>
          <p align="center">
            Departamento de Programas de Estudio
            <br>
            Direccion Academica
            <br>
            <br>
              <h5>No es necesario que responda a este correo.</h5>
          </p>
          <p align="center">
            <img src="http://187.189.147.227/assets/images/logo/LOGO-COBATAB2.png" alt="logoCBT">
            <br>"Educacion que Genera Cambio"
          </p>`
        )
        .subscribe((resp: any) => {
          Swal.close();
          // this.actualizaStatusPlanteles(this.cvePlantelActual, arcId, 2, '');
          this.MensajeTemporal.fire({
            icon: 'success',
            title: 'Archivo Aceptado',
          });
        });
    });
  }

  getCorreoPlantel(uniadmClave: number) {
    this.academiasSrv
      .getCorreoPlantel(uniadmClave)
      .subscribe((resp: Respuesta) => {
        sessionStorage.setItem('correoPlantel', resp.data[0].correo);
      });
  }
}

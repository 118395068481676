import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment.prod";
import { Respuesta } from '../interfaces/materias.interface';

@Injectable({
  providedIn: 'root'
})
export class ControlService {

  private url = `${environment.url}/api`;
  // private url = `${environment.url}:${environment.port}/api`;
  private urlFile = `${environment.url}/api`;
  // private urlFile = `${environment.url}:${environment.portFile}/api`;

  constructor(private http: HttpClient) { }

  getPlanteles() {
    return this.http.get<Respuesta>(`${this.url}/dashboard/getPlanteles`);
  }

  conteoArchivos(eveId: number, uniadmClave: number) {
    return this.http.get<Respuesta>(`${this.url}/dashboard/cuentaArchivos/${eveId}/${uniadmClave}`);
  }

  getArchivo(archivo: string) {
    return this.http.get<Respuesta>(`${this.urlFile}/upload/academias/${archivo}`);
  }

  aceptaArchivo(arcId: number) {
    return this.http.get<Respuesta>(`${this.url}/dashboard/acepta/${arcId}`);
  }

  rechazaArchivo(arcId: number, detalle: string) {
    let info = { detalle };
    return this.http.post<Respuesta>(`${this.url}/dashboard/rechaza/${arcId}`, info);
  }

  getPlantelesConteo(eveId: number) {
    return this.http.get<Respuesta>(`${this.url}/dashboard/cuenta/${eveId}`);
  }

  getCuantosARchivosEvento(eveId: number) {
    return this.http.get<Respuesta>(`${this.url}/dashboard/totalArchivos/${eveId}`);
  }

  getArchivosPlantelEvento(eveId: number, uniadmClave: number) {
    return this.http.get<Respuesta>(`${this.url}/dashboard/cuentaArchivos/${eveId}/${uniadmClave}`);
  }

  agregaComentarioPlantel(eveId: number, uniadmCve: number, detalle: string) {
    return this.http.post<Respuesta>(`${this.url}/dashboard/detalle/${eveId}/${uniadmCve}`, { detalle });
  }

  editaComentarioPlantel(eveId: number, uniadmCve: number, detalle: string) {
    return this.http.put<Respuesta>(`${this.url}/dashboard/detalle/${eveId}/${uniadmCve}`, { detalle });
  }

  eliminaComentarioPlantel(adeId: number) {
    return this.http.delete<Respuesta>(`${this.getCuantosARchivosEvento}/dashboard/detalle/${adeId}`);
  }
}

import { FileUploadService } from './../servicios/file-upload.service';
import { AcademiasService } from './../servicios/academias.service';
import { Component, OnInit } from '@angular/core';
import { Respuesta, ArchivoSubido, Evento } from './../interfaces/materias.interface';

import Swal from 'sweetalert2';
// import { getMaxListeners } from 'process';

@Component({
  selector: 'app-repositorio',
  templateUrl: './repositorio.component.html',
  styleUrls: ['./repositorio.component.css']
})
export class RepositorioComponent implements OnInit {
  public uniadm: number = parseInt(sessionStorage.getItem('uniadmClave'));
  public turno: string = sessionStorage.getItem('turno').substr(0, 1);
  public elPeriodo: string;
  public elEvento: number;
  public losEventos: Evento[] = [];
  public losPeriodos: { periodo: string }[] = [];
  public tpaId: number;
  public archivos: ArchivoSubido[];
  public tipo_archivo: string;
  public archivoSubir: File;
  public nombreSubir: string;
  public tipoSubir: string;
  public tpa_nombre: string;

  constructor(private academiasSrv: AcademiasService,
    private fileUploadService: FileUploadService) { }

  ngOnInit(): void {
    this.getPeriodos();
    this.getCorreoPlantel();
  }

  getPeriodos() {
    this.losPeriodos = [];
    this.academiasSrv.getPeriodos().subscribe((resp: Respuesta) => {
      this.losPeriodos = resp.data;
      console.log(this.losPeriodos);
      this.elPeriodo = this.losPeriodos[0].periodo;
      this.getEventosPeriodo(this.elPeriodo);
    });
  }

  getEventosPeriodo(periodo: string) {
    this.academiasSrv.getEventosPeriodo(periodo).subscribe((resp: Respuesta) => {
      this.losEventos = resp.data;
      this.elEvento = this.losEventos[0].eve_id;
      this.getArchivosPlantelEvento(this.elPeriodo, this.elEvento);
    })
  }

  getArchivosPlantelEvento(periodo: string, evento: number) {
    console.log('getArchivosPlantelEvento');
    this.academiasSrv.getArchivosPlantelEvento(evento, this.uniadm).subscribe((resp: Respuesta) => {
      this.archivos = resp.data;
      console.log(this.archivos);
    });
  }

  agarraArchivo(archivo: File, tipoSubir: string, tpa_id: number, tpa_nombre: string) {
    this.archivoSubir = archivo;
    this.tipoSubir = tipoSubir;
    this.tpaId = tpa_id;
    this.tpa_nombre = tpa_nombre;
    this.nombreSubir = this.elPeriodo + '_' + this.elEvento + '_' + this.uniadm + '_' + this.turno + '_' + tipoSubir + '.pdf';
  }

  subeArchivo() {
    Swal.fire({
      title: 'Subiendo archivo...',
      allowEscapeKey: false,
      allowOutsideClick: false
    });
    Swal.showLoading();
    this.fileUploadService.actualizaFoto(this.archivoSubir, this.nombreSubir).subscribe((resp: any) => {
      this.registraArchivo(resp.nombreArchivo);
    });
  }

  registraArchivo(archivo: string) {
    this.academiasSrv.registraArchivo(this.tpaId, this.elEvento, 1, this.uniadm, archivo).subscribe((resp: Respuesta) => {
      this.academiasSrv.enviaCorreo(
        sessionStorage.getItem('correoPlantel'),
        'Confirmacion de documento recibido.',
        ` <p>Director:</p>
          <p>
            El documento de <strong>${this.tpa_nombre}</strong> ha sido recibido.<br>
            Gracias por el seguimiento al proceso académico.<br>
            Saludos cordiales.
          </p>
          <p align="center">
            Departamento de Programas de Estudio
            <br>
            Direccion Academica
            <br>
          </p>
          <p align="center">
            <img src="http://187.189.147.227/reuniondeacademias/logo.jpg" alt="logoCBT">
            <br>"Educacion que Genera Cambio"
          </p>`).subscribe((resp: any) => {
          Swal.close();
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          Toast.fire({
            icon: 'success',
            title: 'Archivo Subido'
          })
          this.tipoSubir = '';
          this.tpaId = 0;
          this.getArchivosPlantelEvento(this.elPeriodo, this.elEvento);
        });
    });
  }

  deleteArchivo(id: number) {
    this.academiasSrv.deleteArchivo(id).subscribe((resp: any) => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'info',
        title: 'Archivo Eliminado'
      })
      this.getArchivosPlantelEvento(this.elPeriodo, this.elEvento);
    })
  }

  getCorreoPlantel() {
    this.academiasSrv.getCorreoPlantel(this.uniadm).subscribe((resp: Respuesta) => {
      sessionStorage.setItem('correoPlantel', resp.data[0].correo);
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Respuesta, Grupo } from './../../interfaces/materias.interface';
import { AcademiasService } from './../../servicios/academias.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-grupos',
  templateUrl: './grupos.component.html',
  styleUrls: ['./grupos.component.css']
})
export class GruposComponent implements OnInit {
  grupos: Grupo[];
  materias = [];
  materiasDisponibles = [];
  id: number = 0;
  errorNombreGrupo: boolean = false;
  constructor(
    private aService: AcademiasService,
    private modalSrv: NgbModal) { }

  ngOnInit(): void {
    this.getGrupos();
  }

  open(content) {
    this.materiasDisponibles = [];
    this.modalSrv.open(content, { size: 'lg' });
    this.getMateriasDisponibles();
  }

  modalGrupo(content) {
    this.modalSrv.open(content);
    (<HTMLInputElement>document.getElementById('idGrupo')).value = '';
    (<HTMLInputElement>document.getElementById('nombreGrupo')).value = '';
  }

  modalGrupoEdit(content) {
    this.modalSrv.open(content);
    let e = (document.getElementById('grupo')) as HTMLSelectElement;;
    let gruId = e.options[e.selectedIndex].value;
    let gruNombre = e.options[e.selectedIndex].text;

    //     Ejemplos
    // var DropdownList = (document.getElementById("ctl00_ContentPlaceHolder1_lvCASec2_Detail_ctrl" + mDropDownID + "_ddlPoints")) as HTMLSelectElement;
    // var SelectedIndex = DropdownList.selectedIndex; // no error


    (<HTMLInputElement>(document.getElementById('idGrupo'))).value = gruId;
    (<HTMLInputElement>(document.getElementById('nombreGrupo'))).value = gruNombre;

  }
  getGrupos() {
    Swal.fire({
      title: 'Cargando Grupos...',
      allowEscapeKey: false,
      allowOutsideClick: false
    });
    Swal.showLoading();
    this.aService.getGrupos().subscribe((resp: Respuesta) => {
      Swal.close();
      this.grupos = resp.data;
    })
  }

  getArchivosGrupo(gruId) {
    this.id = gruId;
    Swal.fire({
      title: 'Cargando Materias...',
      allowEscapeKey: false,
      allowOutsideClick: false
    });
    Swal.showLoading();
    this.aService.getGrupoArchivos(gruId).subscribe((resp: Respuesta) => {
      Swal.close();
      this.materias = resp.data;
    })
  }

  getMateriasDisponibles() {
    Swal.fire({
      title: 'Cargando Materias Disponibles..',
      allowEscapeKey: false,
      allowOutsideClick: false
    });
    Swal.showLoading();
    this.aService.getMateriasDisponibles(this.id).subscribe((resp: Respuesta) => {
      Swal.close();
      this.materiasDisponibles = resp.data;
    })
  }

  agregaMateriaAGrupo(matId: number) {
    Swal.fire({
      title: 'Agregando Materia al Grupo...',
      allowEscapeKey: false,
      allowOutsideClick: false
    });
    Swal.showLoading();
    this.aService.agregaMateriaAGrupo(this.id, matId).subscribe((resp: Respuesta) => {
      Swal.close();
      this.getArchivosGrupo(this.id);
      this.getMateriasDisponibles();
    })
  }

  eliminaMateriaGrupio(garId: number) {
    Swal.fire({
      title: 'Eliminando Materia de Grupo',
      allowEscapeKey: false,
      allowOutsideClick: false
    });
    Swal.showLoading();
    this.aService.eliminaMateriaGrupo(garId).subscribe((resp: Respuesta) => {
      this.getArchivosGrupo(this.id);
    })
  }

  saveGrupo() {
    this.errorNombreGrupo = false;
    let gruId = (<HTMLInputElement>(document.getElementById('idGrupo'))).value;
    let nombreGrupo = (<HTMLInputElement>(document.getElementById('nombreGrupo'))).value;

    if (!nombreGrupo) {
      this.errorNombreGrupo = true;
      return;
    }

    if (!gruId) {
      console.log('a guardar ', nombreGrupo);
      Swal.fire({
        title: 'Guardando..',
        allowEscapeKey: false,
        allowOutsideClick: false
      });
      this.aService.addGrupo(nombreGrupo).subscribe(resp => {
        Swal.close();
        this.modalSrv.dismissAll();
        this.getGrupos();
      });
    } else {
      console.log('actualiza ', nombreGrupo);
      Swal.fire({
        title: 'Guardando..',
        allowEscapeKey: false,
        allowOutsideClick: false
      });
      this.aService.updGrupo(gruId, nombreGrupo).subscribe(resp => {
        Swal.close();
        this.modalSrv.dismissAll();
        this.getGrupos();
      });
    }
  }

}
<div class="card">
  <div class="card-header">
    Materias
    <i class="fas fa-plus-circle text-primary" (click)="nuevo()"></i>
  </div>
  <div class="card-body">

    <form autocomplete="off" #f="ngForm" (ngSubmit)="guardar(f);">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">CLAVE</th>
            <th scope="col">NOMBRE</th>
            <th scope="col">SEMESTRE</th>
            <th scope="col">OPCIONES</th>
          </tr>
        </thead>
        <tbody>

          <tr *ngIf="formNuevo">
            <td><input required [(ngModel)]="materia.clave" type="text" name="clave" id="clave" class="form-control">
            </td>
            <td><input required [(ngModel)]="materia.nombre" type="text" name="nombre" id="nombre" class="form-control">
            </td>
            <td><input required [(ngModel)]="materia.semestre" type="number" name="semestre" id="semestre"
                class="form-control" min="0" max="6"></td>
            <td>
              <button type="submit" class="btn btn-primary" (click)="guardar(f)"><i class="fas fa-save"></i></button>
              <button type="button" class="btn btn-danger" (click)="cancelar()"><i class="fas fa-window-close"></i></button>
            </td>
          </tr>
          <tr *ngFor="let m of materias">
            <td>{{m.clave}}</td>
            <td>{{m.nombre}}</td>
            <td>{{m.semestre}}</td>
            <td>
              <i class="_cursor far fa-edit text-warning"></i>
              &nbsp;
              &nbsp;
              <i class="_cursor fas fa-trash-alt text-danger"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </form>


  </div>
</div>
import { AuthGuard } from './servicios/auth.guard';
import { MateriasComponent } from './adm/materias/materias.component';
import { GruposComponent } from './adm/grupos/grupos.component';
import { EventosComponent } from './adm/eventos/eventos.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RepositorioComponent } from './repositorio/repositorio.component';
import { LoginComponent } from './auth/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'repositorio', component: RepositorioComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'adm/eventos', component: EventosComponent, canActivate: [AuthGuard] },
  { path: 'adm/grupos', component: GruposComponent, canActivate: [AuthGuard] },
  { path: 'adm/materias', component: MateriasComponent, canActivate: [AuthGuard] },
  { path: '**', pathMatch: 'full', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './auth/login/login.component';
import { RepositorioComponent } from './repositorio/repositorio.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EventosComponent } from './adm/eventos/eventos.component';
import { MateriasComponent } from './adm/materias/materias.component';
import { GruposComponent } from './adm/grupos/grupos.component';
import { NavbarComponent } from './shared/navbar/navbar.component';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RepositorioComponent,
    DashboardComponent,
    EventosComponent,
    MateriasComponent,
    GruposComponent,
    NavbarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [{
    provide: LOCALE_ID, useValue: 'es'
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventosSrvService {
  private url = `${environment.url}/api`;
  // private url = `${environment.url}:${environment.port}/api`;

  constructor(private http: HttpClient) {}

  getEventos() {
    return this.http.get(`${this.url}/eventos/eventos`);
  }
}

import { Component, OnInit } from '@angular/core';
import { Respuesta, Evento } from "../../interfaces/materias.interface";
import { EventosSrvService } from './eventos-srv.service';



@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.css']
})
export class EventosComponent implements OnInit {

  eventos: Evento[] = [];

  constructor(
    private _EvSrv: EventosSrvService
  ) { }

  ngOnInit(): void {
    this.getEventos();
  }

  getEventos() {
    this.eventos = [];
    this._EvSrv.getEventos().subscribe((resp: Respuesta) => {
      this.eventos = resp.data;
      console.log(this.eventos);
    });
  }


}

<div class="col-md-6 offset-md-3">
  <form autocomplete="off" #f="ngForm" (ngSubmit)="onSubmit(f)">
    <div class="card">
      <div class="card-header">Acceso</div>
      <div class="card-body">

        <div class='form-group row'>
          <label for='user' class='col-sm-4 col-form-label text-right'>Usuario</label>
          <div class='col-sm-8'>
            <input type='text' [ngModel]="usuario.user" class='form-control' id='user' name="user" required
              minlength="3">
          </div>
        </div>

        <div class='form-group row'>
          <label for='pass' class='col-sm-4 col-form-label text-right'>Contraseña</label>
          <div class='col-sm-8'>
            <input type='password' [ngModel]="usuario.pass" class='form-control' id='pass' name="pass" required
              minlength="3">
          </div>
        </div>

      </div>

      <div class="card-footer">
        <button class="btn btn-block btn-primary">Ingresar</button>
      </div>
    </div>
  </form>
</div>
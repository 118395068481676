<div class="card">
  <div class="card-header">Dashboard</div>
  <div class="card-body">

    <div class='form-group row'>
      <div class="col-md-6 row">
        <label for='periodo' class='col-sm-3 col-form-label text-right'>Periodo</label>
        <div class='col-sm-9'>
          <select name="periodo" id="periodo" class="form-control" [(ngModel)]='elPeriodo'
            (change)="getEventosPeriodo($event.target.value)">
            <option *ngFor="let per of losPeriodos" value="{{per.periodo}}">{{per.periodo}}</option>
          </select>
        </div>
      </div>

      <div class="col-md-6 row">
        <label for='evento' class='col-sm-3 col-form-label text-right'>Evento</label>
        <div class='col-sm-9'>
          <select name="evento" id="evento" class="form-control" [(ngModel)]="elEvento" (change)="getPlanteles()">
            <option *ngFor="let ev of losEventos" value="{{ev.eve_id}}">{{ev.tpe_nombre}}</option>
          </select>
        </div>
      </div>
    </div>
    <hr>
    <!-- <div class="btn-group alert-light" role="group" *ngIf="losPlantelesFil.length>0">
      <button type="button" [ngClass]="{'btn-info':filtro=='E','btn-outline-info':filtro!='E'}" class="btn btn-sm"
        (click)="filtraPlanteles('E')">Emsad</button>
      <button type="button" [ngClass]="{'btn-info':filtro=='B','btn-outline-info':filtro!='B'}" class="btn btn-sm"
        (click)="filtraPlanteles('B')">BiCultural</button>
    </div> -->
    <br>
    <br>
    <div class="row">

      <div class="col-sm-4" *ngFor="let pl of losPlantelesFil; index as i">
        <div class="card mb-2 _borde">
          <div class="card-header">
            <div class="progress" style="height: 5px;">
              <div class="progress-bar bg-info" role="progressbar"
                style="width: {{porcentaje(pl.archivos,totalArchivosEvento)}}%" aria-valuemin="0" aria-valuemax="100">
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="_divPlantel">{{pl.uniadmAbreviatura}}</div>
            <p class="clearfix">
              <span class="float-left"><strong>{{pl.uniadmDesc}}</strong></span>
              <span class="float-right">{{pl.archivos}} de {{totalArchivosEvento}}
                <i class="fas fa-info-circle text-info _cursor" (click)="open(content, i)"></i>
              </span>
            </p>

            <div class="clearfix align-baseline" *ngIf="pl.detalle==null">
              <button type="button" class="btn btn-sm btn-success float-right"
                (click)="guardaComentario(pl.uniadmClave, pl.uniadmDesc)">Comentario</button>
            </div>

            <!-- <br> -->

            <div *ngIf="pl.detalle!=null">
              <div class="clearfix">
                <strong class="float-left">Comentario:</strong>
                <!-- <div class="btn-group float-right" role="group">
                  <button type="button" class="btn btn-sm btn-white"><i
                      class="text-warning _icono fas fa-pencil-alt"></i></button>
                  <button type="button" class="btn btn-sm btn-white"><i
                      class="text-danger _icono far fa-trash-alt"></i></button>
                </div> -->
              </div>
              <!-- <textarea rows="4"></textarea> -->
            </div>



            <p class="text-justify"><span *ngIf="pl.detalle!=null">{{pl.detalle}}</span></p>

          </div>
        </div>
      </div>

    </div>

  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{plantelActual}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <!-- <label for="dateOfBirth">Archivos Subidos del Plantel</label> -->
        <!-- <button type="button" (click)="cambia()">dale</button> -->
        <div *ngIf='archivosPlantelSeleccionado.length == 0'>
          <i class="fas fa-3x fa-spinner fa-pulse"></i>
          Cargando...
        </div>
        <div class="input-group" *ngIf='archivosPlantelSeleccionado.length > 0'>
          <ul class="list-group list-group-flush w-100">
            <li *ngFor="let arc of archivosPlantelSeleccionado"
              class="list-group-item list-group-item-action listaArchivo" [ngClass]="{
                  'list-group-item-primary':arc.arcStatus==1,
                  'list-group-item-success':arc.arcStatus==2,
                  'list-group-item-warning':arc.arcStatus==3,
                  'list-group-item-warning':arc.arcStatus==4
                }">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{arc.tpaNombre}} <span *ngIf="arc.status">[{{arc.status}}]</span></h5>
                <!-- <span *ngIf="arc.arcStatus==1" class="badge badge-pill badge-primary">{{arc.status}}</span>
                <span *ngIf="arc.arcStatus==2" class="badge badge-pill badge-success">{{arc.status}}</span>
                <span *ngIf="arc.arcStatus==3" class="badge badge-pill badge-warning">{{arc.status}}</span> -->
                <div class="justify-content-between" *ngIf="arc.arcArchivo">
                  <span class="badge _cursor badge-danger float-right ml-1" *ngIf="arc.arcStatus==1 || arc.arcStatus==4"
                    (click)="rechazaArchivo(arc.arcId,arc.tpaNombre)">
                    <!-- A Revisión -->
                    <i class="fas fa-undo"></i>
                  </span>
                  <span class="badge _cursor badge-success float-right ml-1" *ngIf="arc.arcStatus!=2"
                    (click)="aceptaArchivo(arc.arcId,arc.tpaNombre)">
                    <!-- Aceptar -->
                    <i class="fas fa-check"></i>
                  </span>
                  <span class="badge _cursor badge-info float-right ml-1"
                    (click)="revisarArchivo(arc.arcArchivo,modalDocumento,arc.tpaNombre)">
                    <!-- Archivo -->
                    <i class="far fa-file"></i>
                  </span>
                </div>
                <div class="justify-content-between" *ngIf="!arc.arcArchivo">
                  <span class="badge badge-secondary float-right ml-1">Sin registro de archivo</span>
                </div>
              </div>

              <small>{{arc.arcDetalles}}</small>
            </li>
          </ul>

        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Cerrar</button>
  </div>
</ng-template>


<!-- Modal del Documento -->
<ng-template #modalDocumento let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{tipoArchivoVer}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <iframe [src]="archivoMuestra" width="100%" height="500px"></iframe>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Cerrar</button>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { LoginModel } from './../models/usuarioModel';
import { Menus } from './../interfaces/menu.interface';
import { Materia } from './../interfaces/materias.interface';
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment.prod";

@Injectable({
  providedIn: 'root'
})
export class AcademiasService {

  logueado = false;
  public menu = [];
  private url = `${environment.url}/api`;
  // private url = `${environment.url}:${environment.port}/api`;
  private authUrl = `${environment.AuthUrl}/api/seguridad/loginSGII`;
  // private authUrl = `${environment.AuthUrl}:${environment.AutPort}/api/seguridad/loginSGII`;
  private guardaMateria = `tpArchivo/tipoArchivo`;

  private menupl: Menus[] =
    [
      { url: 'repositorio', titulo: 'Repositorio' }
    ];

  private menudg: Menus[] =
    [
      { url: 'dashboard', titulo: 'Dashboard' },
      {
        url: '0', titulo: 'Administración',
        submenu: [
          { url: 'adm/eventos', titulo: 'Eventos' },
          { url: 'adm/grupos', titulo: 'Grupos' },
          { url: 'adm/materias', titulo: 'Materias' }
        ]
      }
    ];

  constructor(private http: HttpClient) { }

  getEventos() {
    return this.http.get(`${this.url}/eventos/eventos`);
  }

  getMenuPlantel() { return this.menupl; }

  getMenuDG() { return this.menudg; }

  revisaMenus() {
    (parseInt(sessionStorage.getItem('logueado')) == 1) ? this.logueado = true : this.logueado = false;
    if (this.logueado) {
      this.menu = JSON.parse(sessionStorage.getItem('menu'));
    }
  }

  login(user: LoginModel): any {
    return this.http.post(`${this.authUrl}`, user).pipe(
      map((resp: any) => {
        if (!resp.ok) {
          const info = {
            error: true,
            msg: 'Usuario o Contraseña Incorrecto'
          }
          return info;
        }
        sessionStorage.setItem('rol', resp['data']['rol']);
        sessionStorage.setItem('nombreCompleto', resp['data']['nombreCompleto']);
        sessionStorage.setItem('rol', resp['data']['rol']);
        sessionStorage.setItem('turno', resp['data']['turno']);
        sessionStorage.setItem('turnoId', resp['data']['turnoId']);
        sessionStorage.setItem('uniadmClave', resp['data']['uniadmClave']);
        sessionStorage.setItem('usuAdmin', resp['data']['usuAdmin']);
        sessionStorage.setItem('usuSistema', resp['data']['usuSistema']);
        sessionStorage.setItem('logueado', '1');
        sessionStorage.setItem('token', resp.token);
        this.logueado = true;

        // traer el nombre del plantel?

        switch (resp['data']['rol']) {
          case 'SISTEMA':
            sessionStorage.setItem('menu', JSON.stringify(this.menudg));
            this.menu = this.menudg;
            break;

          case 'DIRECTOR':
            sessionStorage.setItem('menu', JSON.stringify(this.menupl));
            this.menu = this.menupl;
            break;
        }

        return {
          error: false,
          nombre: resp['data']['nombreCompleto'],
          rol: resp['data']['rol'],
          uniadmClave: resp['data']['uniadmClave'],
          usuAdmin: resp['data']['usuAdmin'],
          usuSistema: resp['data']['usuSistema']
        }
      })
    );
  }

  addTipoArchivo(tparchivo: Materia) {
    return this.http.post(`${this.url}/${this.guardaMateria}`, tparchivo)
  }

  getMaterias() {
    return this.http.get(`${this.url}/tpArchivo/tipoArchivos`)
  }

  // ==============================
  //  Grupos
  // ==============================
  getGrupos() {
    return this.http.get(`${this.url}/grupos/grupos`)
  }

  getGrupoArchivos(id: number) {
    return this.http.get(`${this.url}/gpoArchivo/gpoArchivo/${id}`)
  }

  agregaMateriaAGrupo(gruId: number, matId: number) {
    let datos = { matId: matId };
    return this.http.post(`${this.url}/gpoArchivo/gpoArchivo/${gruId}`, datos);
  }

  eliminaMateriaGrupo(garId: number) {
    return this.http.delete(`${this.url}/gpoArchivo/gpoArchivo/${garId}`);
  }

  addGrupo(nombre: string) {
    console.log(nombre);
    let data = { nombre: nombre };
    console.log(data);
    return this.http.post(`${this.url}/grupos/grupo`, data);
  }

  updGrupo(gruId: string, gruNombre: string) {
    let datos = {
      nombre: gruNombre
    }
    return this.http.put(`${this.url}/grupos/grupo/${gruId}`, datos)
  }

  // ==============================
  //  Archivos Plantel Evento
  // ==============================
  getArchivosPlantelEvento(evento: number, plantel: number) {
    return this.http.get(`${this.url}/archivo/archivo/evento/${evento}/${plantel}`);
  }

  getMateriasDisponibles(id: number) {
    return this.http.get(`${this.url}/tpArchivo/materiasDisponibles/${id}`)
  }

  // TODO: Hacer su propio servicio
  getPeriodos() {
    return this.http.get(`${this.url}/eventos/getPeriodos`);
  }

  getEventosPeriodo(periodo: string) {
    return this.http.get(`${this.url}/eventos/getEventosPeriodo/${periodo}`);
  }

  registraArchivo(tpa_id: number, eve_id: number, conteo: number = 1, uniadm_clave: number, archivo: string) {
    let datos = { tpa_id, eve_id, conteo, uniadm_clave, archivo }
    return this.http.post(`${this.url}/archivo/archivo`, datos);
  }

  deleteArchivo(id: number) {
    return this.http.delete(`${this.url}/archivo/archivo/${id}`);
  }

  getCorreoPlantel(uniadmClave: number) {
    return this.http.get(`${this.url}/archivo/getCorreo/${uniadmClave}`);
  }

  enviaCorreo(destino: string, asunto: string, mensaje: string) {
    let params = { destino, asunto, mensaje }
    return this.http.post(`${environment.AuthUrl}/api/mail/academia`, params);
    // return this.http.post(`${environment.AuthUrl}:${environment.AutPort}/api/mail/academia`, params);
  }

}

import { LoginModel } from '../../models/usuarioModel';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import md5 from 'md5-hash';
import Swal from 'sweetalert2';

import { AcademiasService } from '../../servicios/academias.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  usuario: LoginModel;
  elUser: LoginModel;

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  constructor(private sAcademia: AcademiasService, private router: Router) {
    this.sAcademia.revisaMenus();
  }


  ngOnInit(): void {
    this.usuario = new LoginModel();
    this.elUser = new LoginModel();
    this.sAcademia.revisaMenus();
  }

  onSubmit(f: NgForm): void {
    this.elUser.user = f.value.user;
    this.elUser.pass = md5(f.value.pass);
    Swal.fire({
      title: 'Autenticando...',
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    Swal.showLoading();
    this.sAcademia.login(this.elUser).subscribe((res: any) => {
      Swal.close();
      console.log(res);
      if (res.error) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Usuario o Contraseña Incorrecto',
          showConfirmButton: false,
          timer: 1500
        });
        return;
      }
      /*
      Nuevos roles  
      PARA EMSAD ACADEMIA EMSAD , RESPONSABLE EMSAD, PARA LOS DEMAS PLANTELES ACADEMIA_PLANTEL DIRECTOR
      */
      switch (res.rol) {
        case 'SUBDIRECTOR EMSAD': this.accesa(1); break;
        case 'ACADEMIA EMSAD': this.accesa(0); break;
        case 'RESPONSABLE EMSAD': this.accesa(1); break;
        case 'SISTEMA': this.accesa(0); break;
        default: this.accesoDenegado(); break;
      }
    });
  }

  accesa(permiso: number): void {
    this.Toast.fire({
      icon: 'success',
      title: 'Logueado'
    });
    // tslint:disable-next-line:triple-equals
    if (permiso == 0) {
      this.router.navigateByUrl('/dashboard');
    } else {
      this.router.navigateByUrl('/repositorio');
    }
  }

  accesoDenegado(): void {
    Swal.fire({
      icon: 'warning',
      // title: 'Oops...',
      text: 'La plataforma es para Directores y Subdirectores',
    });
  }

}

<div class="card">
  <div class="card-header">Eventos</div>
  <div class="card-body">

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">PERIODO</th>
          <th scope="col">EVENTO</th>
          <th scope="col">FECHA DE INICIO</th>
          <th scope="col">FECHA DE CIERRE</th>
          <th scope="col">STATUS</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ev of eventos">
          <td>{{ev.eve_periodo}}</td>
          <td>{{ev.tpe_nombre}}</td>
          <td>{{ev.f_inicio}}</td>
          <td>{{ev.f_fin}}</td>
          <td>{{ev.status}}</td>
        </tr>
      </tbody>
    </table>


  </div>
</div>
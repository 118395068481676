import { AcademiasService } from './../../servicios/academias.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  menu = [];
  logueado = false;
  constructor(public aService: AcademiasService, private router: Router) { }

  ngOnInit(): void {
    this.aService.revisaMenus();
    this.menu = this.aService.menu;
    (parseInt(sessionStorage.getItem('logueado')) == 1) ? this.logueado = true : this.logueado = false;
  }

  salir() {
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }

}

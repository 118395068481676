import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

const url = environment.url;
@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private url = `${environment.urlFile}/api`;
  // private url = `${environment.url}:${environment.portFile}/api`;
  constructor(private http: HttpClient) { }

  subeArchivo() {
    // tipo=academias
  }

  actualizaFoto(archivo: File, nombre: string) {
    const endPoint = `${this.url}/upload/academias`;
    const formData = new FormData();
    formData.append('archivo', archivo);
    formData.append('nombre', nombre);

    // const resp = await fetch(endPoint, {
    //   method: 'POST',
    //-- headers: {'x-token': sessionStorage.getItem('token') || ''},
    //   body: formData
    // });

    return this.http.post(`${endPoint}`, formData);

    // return resp.json();
    // console.log(data);
    // return data;
  }

}

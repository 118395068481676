import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Materia, Respuesta } from './../../interfaces/materias.interface';
import { AcademiasService } from './../../servicios/academias.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-materias',
  templateUrl: './materias.component.html',
  styleUrls: ['./materias.component.css']
})

export class MateriasComponent implements OnInit {
  formNuevo = false;
  materias: Materia[];
  materia: Materia = {
    clave: '',
    nombre: '',
    semestre: 0
  };
  constructor(private aService: AcademiasService) {
  }

  ngOnInit(): void {
    this.getMaterias();
  }

  nuevo() {
    this.formNuevo = true;
  }

  cancelar() {
    this.formNuevo = false;
  }

  getMaterias() {
    Swal.fire({
      title: 'Cargando Materias...',
      allowEscapeKey: false,
      allowOutsideClick: false
    });
    Swal.showLoading();
    this.aService.getMaterias().subscribe((resp: Respuesta) => {
      Swal.close();
      this.materias = resp.data;
    })
  }

  guardar(f: NgForm) {
    if (f.invalid) {
      console.log('Formulario Incompleto');
    }
    // this.aService.addTipoArchivo(this.materia).subscribe((resp: Respuesta) => {});
  }

}
<div class="card">
  <div class="card-header">Grupos</div>
  <div class="card-body">

    <div class='form-group row'>
      <label for='grupo' class='col-sm-1 col-form-label text-right'>Grupo</label>
      <div class='col-sm-6'>
        <select name="grupo" id="grupo" class='form-control' (change)="getArchivosGrupo($event.target.value)" #cmbGrupo>
          <option value="0">Selecciona un grupo...</option>
          <option *ngFor="let g of grupos" value="{{g.id}}">{{g.nombre}}</option>
        </select>
      </div>
      <div class="btn-group" role="group" aria-label="Basic example">
        <button type="button" class="btn btn-sm btn-primary" (click)="modalGrupo(modGrupo)">
          <i class="_cursor fas fa-plus-circle"></i>
          Nuevo Grupo
        </button>
        <button *ngIf="this.id!=0" type="button" class="btn btn-sm btn-warning" (click)="modalGrupoEdit(modGrupo)">
          <i class="_cursor far fa-edit"></i>
          Cambiar Nombre
        </button>
        <button *ngIf="this.id!=0" type="button" class="btn btn-sm btn-success" (click)="open(addMateria)">
          <i class="_cursor fas fa-plus-circle"></i>
          Agregar Materia
        </button>
      </div>
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">CLAVE</th>
          <th scope="col">NOMBRE</th>
          <th scope="col">SEMESTRE</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="4" class="text-center" *ngIf="materias.length==0">No hay materias en el grupo</td>
        </tr>
        <tr *ngFor="let mat of materias">
          <td>{{mat.clave}}</td>
          <td>{{mat.nombre}}</td>
          <td class="text-center">{{mat.semestre}}</td>
          <td>
            <i class="_cursor fas fa-trash-alt text-danger" (click)="eliminaMateriaGrupio(mat.gar_id)"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #addMateria let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Materias Disponibles</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')"><span>&times;</span></button>
  </div>
  <div class="modal-body">
    <ul class="list-group">
      <li *ngIf="materiasDisponibles.length==0"
        class="list-group-item list-group-item-danger list-group-item-action d-flex justify-content-between align-items-center">
        No Hay Materias Disponibles
      </li>
      <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
        *ngFor="let md of materiasDisponibles">
        {{md.nombre}} [{{md.clave}}]
        <i class="_cursor text-right fas fa-plus-circle text-primary" (click)="agregaMateriaAGrupo(md.id)"></i>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #modGrupo let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Nuevo Grupo</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')"><span>&times;</span></button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-inline">
        <label for="nombreGrupo">Nombre del Grupo</label>
        <input type="hidden" id="idGrupo" name="idGrupo">
        <input type="text" class="form-control col-sm-12" id="nombreGrupo" autofocus>
        <button class="btn btn-primary" (click)="saveGrupo();">Guardar</button>
        <small *ngIf="errorNombreGrupo" class="form-text text-muted">El nombre del grupo no puede esta vacío.</small>
      </div>
    </form>
  </div>
</ng-template>
export class UsuarioModel {
  usuario: string;
  rol: string;
  empClave: number;
  usuAdmin: string;
  nombreCompleto: string;
  usuSistema: string;
  periodo: string;
  fecha: string;
  uniadmClave: number;
  turnoId: number;
  turno: string;
}

export class LoginModel {
  user: string;
  pass: string;
}

<div class="card mb-3">
  <div class="card-header">Repositorio de Archivos</div>
  <div class="card-body">

    <div class='form-group row'>

      <div class="col-md-6 row">
        <label for='periodo' class='col-sm-3 col-form-label text-right'>Periodo</label>
        <div class='col-sm-9'>
          <select name="periodo" id="periodo" class="form-control" [(ngModel)]='elPeriodo'
            (change)="getEventosPeriodo($event.target.value)">
            <option *ngFor="let per of losPeriodos" value="{{per.periodo}}">{{per.periodo}}</option>
          </select>
        </div>
      </div>

      <div class="col-md-6 row">
        <label for='evento' class='col-sm-3 col-form-label text-right'>Evento</label>
        <div class='col-sm-9'>
          <select name="evento" id="evento" class="form-control">
            <option *ngFor="let ev of losEventos" value="{{ev.eve_id}}">{{ev.tpe_nombre}}</option>
          </select>
        </div>
      </div>

    </div>

  </div>
</div>

<div class="alert alert-warning" role="alert">
  Para que sus archivos PDF ocupen menos espacio, puede utilizar la siguiente <a target="_blank"
    href="https://www.ilovepdf.com/es/comprimir_pdf">herramienta en linea</a>.
</div>

<div class="card-columns">
  <div class="card mb-3" *ngFor="let ar of archivos"
    [ngClass]="{'border-danger':ar.arc_status==3,'border-primary':ar.arc_status==2,'border-success':ar.arc_status==1}">
    <div class="card-header" *ngIf="ar.tpa_nombre"
      [ngClass]="{'bg-danger':ar.arc_status==3,'bg-primary':ar.arc_status==2,'bg-success':ar.arc_status==1,'text-white': ar.arc_archivo}">
      <strong>[{{ar.tpa_clave}}] - {{ ar.tpa_nombre }} </strong>
    </div>
    <div class="card-body">
      <div class="card-text">
        <div *ngIf="ar.arc_id">
          <div><strong>Archivo:</strong> {{ ar.arc_archivo }}</div>
          <div><strong>Status:</strong> {{ar.status}}</div>
          <div><strong>Fecha:</strong> {{ar.arc_fecha | date:'EEEE dd, MMMM,yyyy (h:mm a)'}}</div>
          <div class="text-justify alert alert-warning" *ngIf="ar.arc_detalle!=null">
            {{ar.arc_detalle}}
          </div>

          <div class="text-right" *ngIf="ar.arc_status==1">
            <button type="button" class="btn btn-outline-danger mr-2" placement="top" ngbTooltip="Eliminar archivo"
              (click)="deleteArchivo(ar.arc_id)">
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>

        </div>

        <div class="alert alert-info" role="alert" *ngIf="tipoSubir==ar.tpa_clave && !ar.arc_id">
          Archivo listo para subir
        </div>

        <div class="row" *ngIf="!ar.arc_id || ar.arc_status && ar.arc_status==3">
          <div class="btn-group">
            <input type="file" class="btn border-secondary btn-primary btn-block card-footer" accept="application/pdf"
              (change)="agarraArchivo($event.target.files[0],ar.tpa_clave,ar.tpa_id,ar.tpa_nombre);">
            <button type="button" class="btn btn-outline-primary" [disabled]='tipoSubir!=ar.tpa_clave'
              (click)="subeArchivo()">
              <span *ngIf="!ar.arc_id">Subir Archivo</span>
              <span *ngIf="ar.arc_id || ar.arc_status && ar.arc_status==3">Actualizar Archivo</span>
            </button>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>